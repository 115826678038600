export default {
    mounted: function (el, binding, vNode) {
        el.clickOutsideEvent = event => {
            if (!(el === event.target || el.contains(event.target))) {
                binding.value(event, el)
            }
        };

        document.addEventListener("click", el.clickOutsideEvent);
    },
    unmounted: function (el, binding) {
        document.removeEventListener("click", el.clickOutsideEvent);
    }
}
