import Glide from '@glidejs/glide';
import LazyLoad from "vanilla-lazyload";

require("fslightbox");

window.lazyLoad = new LazyLoad({
    unobserve_entered: true,
    restore_on_error: true,
});

window.lazyLoad.update();

document.addEventListener('DOMContentLoaded', () => {
    addArrowIconEventListener();
    toggleCarousel();
    initializeCarousel();
});

document.addEventListener(
    "ready-for-carousel",
    (e) => {
        toggleCarousel();
    },
    false,
);

const addArrowIconEventListener = () => {
    const headerImage = document.getElementsByClassName('arrow-icon');
    const container = document.getElementsByClassName('container-2-p');

    if (headerImage[0]) {
        headerImage[0].addEventListener('click', ($event) => {
            container[0].classList.toggle('active-container-2-p');
            headerImage[0].classList.toggle('active-icon');
        });
    }
}

let glideInstances = [];

let mountCarousel = (element) => {

    glideInstances.forEach(glide => {
        if (element.id === glide.selector.id) {
            try {
                const oldNav = element.querySelector('.carousel-nav');
                const newNav = oldNav.cloneNode(true);
                oldNav.parentNode.replaceChild(newNav, oldNav);
                glide.destroy();
                glideInstances = glideInstances.filter(g => g.selector.id !== glide.selector.id);
            } catch (e) {
                console.log(e);
            }
        }
    });

    const glide = new Glide(element, {
        type: 'carousel',
        perView: 6,
        gap: 10,
        startAt: 0,
        breakpoints: {
            1380: {
                perView: 4
            },
            960: {
                perView: 3
            },
            767: {
                perView: 2
            },
        }
    });

    glideInstances.push(glide);

    const glideArrowLeft = element.querySelector('.carousel-nav .arrow-icon-left');
    const glideArrowRight = element.querySelector('.carousel-nav .arrow-icon-right');

    glideArrowLeft.addEventListener('click', function () {
        glide.go('<');
    });
    glideArrowRight.addEventListener('click', function () {
        glide.go('>');
    });

    glide.mount();
}

const toggleCarousel = () => {
    const galleryBtns = document.querySelectorAll('.gallery-switch');
    const carouselBtns = document.querySelectorAll('.carousel-switch');
    const mq = window.matchMedia("(max-width: 960px)");

    if (galleryBtns) {
        galleryBtns.forEach(galleryBtn => {
            galleryBtn.addEventListener('click', () => {

                let container = galleryBtn.closest('.gallery-list-holder');
                let targets = container.querySelectorAll('.grid-list');

                galleryBtn.nextElementSibling.classList.remove('active');
                galleryBtn.classList.add('active');
                if (targets) {
                    targets.forEach(target => {
                        glideInstances.forEach(glide => {
                            if (target.id === glide.selector.id) {
                                try {
                                    const oldNav = target.querySelector('.carousel-nav');
                                    const newNav = oldNav.cloneNode(true);
                                    oldNav.parentNode.replaceChild(newNav, oldNav);
                                    glide.destroy();
                                    glideInstances = glideInstances.filter(g => g.selector.id !== glide.selector.id);
                                } catch (e) {
                                    console.log(e);
                                }
                            }
                        })
                        target.classList.remove('carousel-list');
                    });
                }
            })
        })
    }

    if (carouselBtns) {
        carouselBtns.forEach(carouselBtn => {
            carouselBtn.addEventListener('click', () => {
                let container = carouselBtn.closest('.gallery-list-holder');
                let targets = container.querySelectorAll('.grid-list');

                if (targets) {
                    targets.forEach(target => {
                        let items = target.querySelectorAll('.grid-list__item');
                        if (items.length > 5) {
                            carouselBtn.classList.add('active');
                            carouselBtn.previousElementSibling.classList.remove('active');
                            target.classList.add('carousel-list');
                            mountCarousel(target);
                        }
                    });
                }
            })
        })

        let escortGallery = document.getElementById('js-escortGallery');

        if (mq.matches && escortGallery) {
            setTimeout(function () {
                carouselBtns.forEach(carouselBtn => {
                    carouselBtn.click();
                });
            }, 300);
        }
    }

    let carousels = document.querySelectorAll('.carousel-initial');
    if (carousels) {

        carousels.forEach(carousel => {
            let container = carousel.closest('.gallery-list-holder');
            let carouselsControl = container.querySelector('.carousel-switch');
            let gridControl = container.querySelector('.gallery-switch');
            let items = container.querySelectorAll('.grid-list__item');

            if (carousels && items.length > 5) {
                if (carouselsControl.classList.contains('active')) {
                    carouselsControl.click();
                }
            } else {
                gridControl.click();
            }
        });
    }
    window.lazyLoad.update();
}

// Toggle active class
const activeToggle = document.getElementsByClassName('js-activeToggle');

if (activeToggle) {

    function toggleActiveClass() {
        const closestToggleParent = this.parentNode.closest('.js-toggled');
        closestToggleParent.classList.toggle('active');
    }

    Array.from(activeToggle).forEach(function (element) {
        element.addEventListener('click', toggleActiveClass);
    });
}

// Toggle mobile Menu
const toggleMenuButton = document.getElementsByClassName("js-menuIcon");

if (toggleMenuButton) {

    function toggleMenu() {
        document.body.classList.toggle('menu-active');
    }

    Array.from(toggleMenuButton).forEach(function (element) {
        element.addEventListener('click', toggleMenu);
    });
}

// Site search toggle
const siteSearch = document.querySelector('.header-site-search')
const siteSearchInput = document.querySelector('.header-site-search-input')
const nav = document.querySelector('.js-nav')

if (siteSearch) {
    document.addEventListener('click', (event) => {
        const withinBoundaries = event.composedPath().includes(siteSearch)

        if (withinBoundaries) {
            siteSearch.classList.add('active');

            setTimeout(function () {
                if (siteSearchInput) {
                    siteSearchInput.focus();
                }
            }, 100);
        } else {
            if (!event.composedPath().includes(nav)) {
                siteSearch.classList.remove('active');
            }
        }
    })
}

const siteSearchOverlay = document.querySelector('.header-site-search-overlay')
const siteSearchInputOverlay = document.querySelector('.header-site-search-input-overlay')

if (siteSearchOverlay) {
    document.addEventListener('click', (event) => {
        const withinBoundariesO = event.composedPath().includes(siteSearchOverlay)

        if (withinBoundariesO) {
            siteSearchOverlay.classList.add('active');

            setTimeout(function () {
                if (siteSearchInputOverlay) {
                    siteSearchInputOverlay.focus();
                }
            }, 100);
        } else {
            siteSearchOverlay.classList.remove('active');
        }
    })
}
// end Site search toggle

// To top btn
const toTopBtn = document.getElementById('js-toTopBtn')

function scrollFunctionTop() {
    if (toTopBtn) {
        if (document.body.scrollTop > 300 || document.documentElement.scrollTop > 300) {
            toTopBtn.classList.add('visible');
        } else {
            toTopBtn.classList.remove('visible');
        }
    }
}

toTopBtn.addEventListener('click', () => {
    window.scrollTo(0, 0);
});


// Detached header
function scrollFunctionDetach() {
    if (document.body.scrollTop > 300 || document.documentElement.scrollTop > 300) {
        document.body.classList.add('detached-header');
    } else {
        document.body.classList.remove('detached-header');
    }
}

window.onscroll = function () {
    scrollFunctionDetach();

    if (toTopBtn) {
        scrollFunctionTop();
    }
};

// Tabbed content
const tabs = document.querySelectorAll(".js-tabButton")

tabs.forEach(tab => {
    tab.addEventListener('click', () => {

        let parentTab = tab.closest('.js-tabs');
        let tabContents = parentTab.querySelectorAll('.js-tab');
        let siblingButtons = parentTab.querySelectorAll('.js-tabButton');

        tabContents.forEach(tabContent => {
            tabContent.classList.remove('active')
        })

        siblingButtons.forEach(siblingButton => {
            siblingButton.classList.remove('active')
        })

        tab.classList.add('active')
        let target = document.getElementById(tab.dataset.tabTarget);
        target.classList.add("active");
    })
})

// Toggle collapsible content on mobile
let collapsibleSection = document.querySelectorAll('.jsCollapsibleSection');

if (collapsibleSection) {
    let collapsibleHandlers = document.querySelectorAll('.jsCollapsibleHandler');

    collapsibleHandlers.forEach(collapsibleHandler => {
        collapsibleHandler.addEventListener('click', () => {
            let parentSection = collapsibleHandler.closest('.jsCollapsibleSection');
            parentSection.classList.toggle('active-section');
        })
    })
}

// Bookings carousel

const $carousel = document.querySelector('.bookings-carousel');
const $seats = document.querySelectorAll('.bookings-carousel__item');

if ($seats.length > 1) {
    document.querySelector('.bookings-carousel__step').classList.add('visible');
    $carousel.classList.add('multiple')
}

if ($seats.length === 2) {
    $carousel.classList.add('double')
}

let $totalBookings = document.querySelector('.bookings-total');

if ($totalBookings) {
    $totalBookings.innerHTML = $seats.length;
}

document.addEventListener("click", delegateBooking(toggleBookingFilter, toggleBookingHandler));

// Common helper for event delegation.
function delegateBooking(criteria, listener) {
    return function (e) {
        let el = e.target;
        do {
            if (!criteria(el)) {
                continue;
            }
            e.delegateTarget = el;
            listener.call(this, e);
            return;
        } while ((el = el.parentNode));
    };
}

function initializeCarousel() {
    const $el = document.querySelector('.is-ref');
    if (!$el) return;

    const $newSeat = $el;

    $seats.forEach(seat => {
        seat.classList.remove('is-next', 'is-prev');
    });

    if ($newSeat.nextElementSibling) {
        $newSeat.nextElementSibling.classList.remove('is-ref');
        $newSeat.nextElementSibling.classList.add('is-next');
    }

    if ($newSeat.previousElementSibling) {
        $newSeat.previousElementSibling.classList.remove('is-ref');
        $newSeat.previousElementSibling.classList.add('is-prev');
    }
}

// Custom filter to check for required DOM elements
function toggleBookingFilter(elem) {
    return (elem instanceof HTMLElement) && elem.matches(".nav-arrow ");
}

let $bookingStep = document.querySelector('.bookings-current-step');

// Custom event handler function
function toggleBookingHandler(e) {
    let $newSeat;
    const $el = document.querySelector('.is-ref');
    const $currSliderControl = e.delegateTarget;
    // Info: e.target is what triggers the event dispatcher to trigger and e.currentTarget is what you assigned your listener to.

    $el.classList.remove('is-ref');

    $seats.forEach(seat => {
        seat.classList.remove('is-next', 'is-prev');
    });

    if ($currSliderControl.getAttribute('data-toggle') === 'next') {
        $newSeat = next($el);
        $carousel.classList.remove('is-reversing');
    } else {
        $newSeat = prev($el);
        $carousel.classList.add('is-reversing');
    }

    $newSeat.classList.add('is-ref');

    if ($newSeat.nextElementSibling) {
        $newSeat.nextElementSibling.classList.add('is-next');
    }

    if ($newSeat.previousElementSibling) {
        $newSeat.previousElementSibling.classList.add('is-prev');
    }

    $bookingStep.innerHTML = Array.prototype.indexOf.call($carousel.children, $newSeat) + 1;

    function next($el) {
        if ($el.nextElementSibling) {
            return $el.nextElementSibling;
        } else {
            return $carousel.firstElementChild;
        }
    }

    function prev($el) {
        if ($el.previousElementSibling) {
            return $el.previousElementSibling;
        } else {
            return $carousel.lastElementChild;
        }
    }
}

// Open popups
const popupTriggerBtns = document.querySelectorAll(".js-popupTrigger")

popupTriggerBtns.forEach(popupTriggerBtn => {
    popupTriggerBtn.addEventListener('click', () => {

        let popupContents = document.querySelectorAll('.js-popup');

        popupContents.forEach(tabContent => {
            tabContent.classList.remove('active')
        })

        let target = document.getElementById(popupTriggerBtn.dataset.popupTarget);
        target.classList.add("active");
    })
})

// Close popups
const popupCloseBtns = document.querySelectorAll(".js-popupClose")

popupCloseBtns.forEach(popupCloseBtn => {
    popupCloseBtn.addEventListener('click', () => {

        let popupContents = document.querySelectorAll('.js-popup');

        popupContents.forEach(tabContent => {
            tabContent.classList.remove('active')
        })
    })
})
