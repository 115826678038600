import {createApp, defineAsyncComponent} from 'vue';
import VueLazyload from 'vue-lazyload';
import axios from 'axios';
import _ from 'lodash';
import Swal from 'sweetalert2';
import Form from './classes/Form';
import Errors from './classes/Errors';

import ClickOutside from "./directives/ClickOutside";


// global variables
window.Vue = 'vue';
window.axios = axios;
window._ = _;
window.Swal = Swal;
window.Form = Form;
window.Errors = Errors;

// axios config
window.axios.defaults.baseURL = '/ajax';
window.axios.defaults.withCredentials = true;
window.axios.defaults.headers.common = {
    'X-Requested-With': 'XMLHttpRequest'
};

// vue app
const app = createApp({});

app.use(VueLazyload);
app.directive('click-outside', ClickOutside);

// Define async components
const asyncComponents = {
    'escort-list': defineAsyncComponent(() => import('./components/EscortList')),
    'escorts-browse': defineAsyncComponent(() => import('./components/EscortsBrowse')),
    'escorts-by-location': defineAsyncComponent(() => import('./components/EscortsByLocation')),
    'escort-availability': defineAsyncComponent(() => import('./components/EscortAvailability')),
    'match-instant': defineAsyncComponent(() => import('./components/MatchInstant')),
    'match-special-talent': defineAsyncComponent(() => import('./components/MatchSpecialTalent')),
    'match-tailor-made': defineAsyncComponent(() => import('./components/MatchTailorMade')),
    'match-personality': defineAsyncComponent(() => import('./components/MatchPersonality')),
    'match-zodiac': defineAsyncComponent(() => import('./components/MatchZodiac')),
    'match-chinese-zodiac': defineAsyncComponent(() => import('./components/MatchChineseZodiac')),
    'booking-distance': defineAsyncComponent(() => import('./components/BookingDistance')),
    'membership-application': defineAsyncComponent(() => import('./components/MembershipApplication')),
    'book-now': defineAsyncComponent(() => import('./components/BookNow')),
    'form-personal-match': defineAsyncComponent(() => import('./components/FormPersonalMatch.vue')),
    'profile-about': defineAsyncComponent(() => import('./components/ProfileAbout')),
    'profile-messages': defineAsyncComponent(() => import('./components/ProfileMessages.vue')),
    'rate-calculator': defineAsyncComponent(() => import('./components/RateCalculator')),
    'form-screening': defineAsyncComponent(() => import('./components/FormScreening')),
    'form-recruitment': defineAsyncComponent(() => import('./components/FormRecruitment')),
    'form-recruitment-second': defineAsyncComponent(() => import('./components/FormRecruitmentSecond')),
    'form-feedback': defineAsyncComponent(() => import('./components/FormFeedback')),
    'form-guaranteed-booking': defineAsyncComponent(() => import('./components/FormGuaranteedBooking')),
    'form-profile': defineAsyncComponent(() => import('./components/FormProfile')),
    'form-escort-access': defineAsyncComponent(() => import('./components/FormEscortAccess')),
};

// Register components
for (const component in asyncComponents) {
    app.component(component, asyncComponents[component]);
}

app.mount('#app');
